<template>
  <div class="login-wrapper">
    <div class="logo-bg">
      <img :src="logoText" alt="logoText">
    </div>
    <h3>嘉泰工业管家</h3>
    <div class="form-wrapper">
      <div class="input-item">
        <i class="iconfont iconfont-left iconshouji"></i>
        <input type="text" v-model="username" placeholder="手机号">
        <i class="iconfont iconfont-right iconcha" @click="handleClearUserNameClick"></i>
      </div>
      <div class="input-item">
        <i class="iconfont iconfont-left iconsuo"></i>
        <input type="password" v-if="showPassword" v-model="password" placeholder="请输入密码">
        <input type="text" v-if="!showPassword" v-model="password" placeholder="请输入密码">
        <i class="iconfont iconfont-right iconmima-kanjian" @click="handleSwitchPasswordClick"></i>
      </div>
      <div class="error-tip">{{errorTip}}</div>
      <div class="submit-btn">
        <Button
          :btnTextStyle="btnTextStyle"
          @handleButtonClick="handleLoginClick"
        >登录</Button>
      </div>
      <div class="forget-box">
        <span>忘记密码？</span>
      </div>
    </div>
    <div class="bottom-tip">Powered by @2017-2019 JIATAI.COM.CN</div>
  </div>
</template>

<script>
  import { encrypt } from '@/utils/rsaEncrypt'
  import { mapState, mapActions } from 'vuex'
  import Button from '@/components/Button'
  import logoText from '@/assets/img/login/logo-text.png'
  import { postLogin } from '@/api/login'
  import Vue from 'vue';
  import { Notify } from 'vant';
  import { setStore } from '@/config/mUtils'
  import 'vant/lib/notify/style'
  Vue.use(Notify);
  export default {
    name: 'Login',
    components: {
      Button
    },
    data() {
      return {
        logoText: logoText,
        btnTextStyle: {
          borderRadius: '2rem'
        },
        username: '',
        password: '',
        showPassword: true,
        errorTip: ''
      };
    },
    computed: {
      ...mapState('loading', [
        'loading'
      ])
    },
    created() {
      localStorage.clear();
    },
    methods: {
      ...mapActions('loading', [
        'setLoading'
      ]),
      ...mapActions('user', [
        'setToken',
        'setUser'
      ]),
      /**
       * 清空手机号
       */
      handleClearUserNameClick () {
        this.username = ''
      },
      /**
       * 密码可见可不见
       */
      handleSwitchPasswordClick () {
        this.showPassword = !this.showPassword
      },
      /**
       * 登录
       */
      handleLoginClick () {
        this.errorTip = '';
        if (!this.username) {
          this.errorTip = '手机号不能为空！';
          return;
        }
        if (!this.password) {
          this.errorTip = '密码不能为空！';
          return;
        }

        this.setLoading(true)
        postLogin({
          username: this.username,
          password: encrypt(this.password)
        }).then(res => {
          const { token, user } = res
          if (token && user) {
            Notify({ type: 'success', message: '登录成功！' });
            this.setToken(token);
            // this.setToken('Bearer ' + token);
            this.setUser(user);
            // setStore('jt-device-mobile-token', 'Bearer ' + token)
            setStore('jt-device-mobile-token', token)
            setStore('jt-device-mobile-user', user)
            this.$router.push('/auth')
          }
        }).catch(err => {
          this.errorTip = err;
        }).finally(() => {
          this.setLoading(false)
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "../../style/mixin";
  .login-wrapper{
    @include wh(100%, 100%);
    padding-top: 1rem;
    position: relative;
    min-height: 25rem;
    .logo-bg{
      @include wh(5rem, 5rem);
      @include bis('../../assets/img/login/logo-bg.png');
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 80%;
      }
    }
    h3{
      font-size: 1rem;
      line-height: 3rem;
      color: #333333;
      text-align: center;
      font-weight: 500;
    }
    .form-wrapper{
      width: 100%;
      box-sizing: border-box;
      padding: 0.5rem 1.5rem;
      .input-item{
        @include wh(100%, 2.5rem);
        border-bottom: 1px solid #CCCCCC;
        margin-bottom: 0.6rem;
        position: relative;
        input{
          @include wh(100%, 100%);
          background: white !important;
          box-sizing: border-box;
          padding: 0 2rem;
          font-size: 0.9rem;
        }
        .iconfont-left{
          position: absolute;
          left: 0.5rem;
          font-size: 1.1rem;
          top: 0.6rem;
          font-weight: bold;
          color: #999999;
        }
        .iconfont-right{
          position: absolute;
          right: 0.5rem;
          font-size: 1rem;
          top: 0.7rem;
          font-weight: bold;
          color: #999999;
        }
      }
      .error-tip{
        @include wh(100%, 1.3rem);
        text-align: right;
        color: #FF3333;
        font-size: 0.7rem;
        margin-top: -0.4rem;
      }
      .submit-btn{

      }
      .forget-box{
        @include wh(100%, 2rem);
        text-align: center;
        line-height: 3rem;
        span{
          color: #CCCCCC;
          font-size: 0.9rem;
        }
      }
    }
    .bottom-tip{
      width: 100%;
      position: absolute;
      bottom: 1rem;
      text-align: center;
      font-size: 0.6rem;
      color: #E0E7ED;
    }
  }

</style>
